@import "../../../colors/index.scss";

.submissions-table{
  .cursor-pointer {
    cursor: pointer;
  }
  .MuiChip-root {
    font-weight: bold;
    &.pending {
      border-color: $yellow-status;
      color: $yellow-status;
    }
    &.approved{
      border-color: $green-status;
      color: $green-status;
    }
    &.rejected{
      border-color: $red-status;
      color: $red-status;
    }
  }
}