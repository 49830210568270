@import "../../../colors/index.scss";

.entities-table{
  .cursor-pointer {
    cursor: pointer;
  }
  .MuiChip-root {
    font-weight: bold;
    &.pending{
      border-color: $yellow-status;
      color: $yellow-status;
    }
    &.review{
      border-color: $orange-status;
      color: $orange-status;
    }
    &.paused{
      border-color: $gray-status;
      color: $gray-status;
    }
    &.completed{
      border-color: $green-status;
      color: $green-status;
    }
    &.cancelled{
      border-color: $red-status;
      color: $red-status;
    }
    &.expired{
      background-color: black;
      color: white;
    }
  }
}