.common-layout{
  .title-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    button{
      margin-right: 5px;
      cursor: pointer;
      z-index: 1;
      span{
        align-self: center;
        margin: 5px 0 5px 10px;
        z-index: 0;
      }
    }
    .title{
      margin: 0;
    }
  }
}
