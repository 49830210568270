@import "../../../../colors/index.scss";

.pages-view-deadlines{
  height: 100%;
  margin-bottom: 128px;
  .info-general{
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    
    .row{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .row-title{
        font-weight: bold;
        font-size: 18px;
        margin-right: 4px;
      }
    }
  }
  .versions{
    padding-bottom: 48px;
    .divider{
      margin-bottom: 16px;
    }
    .MuiAlert-root{
      margin-bottom: 16px;
    }
    .version-container{
      &.columns{
        flex-direction: column;
      }
      display: flex;
      flex-direction: row;
      .upload-files{
        margin-right: 16px;
        width: 100%;
      }
      .observations{
        width: 100%;
        .text-area{
          width: 100%;
          margin-bottom: 16px;
        }
      }
      margin-bottom: 96px;
    }
    .MuiChip-root {
      font-weight: bold;
      &.draft{
        border-color: $yellow-status;
        color: $yellow-status;
      }
      &.submitted{
        border-color: $green-status;
        color: $green-status;
      }
      &.deleted{
        border-color: $red-status;
        color: $red-status;
      }
    }
  }
}