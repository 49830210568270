.deadlines-footer-container {
    backdrop-filter: "blur(6px)";
    background-color: #FAFAFA;
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: calc(100% - 244px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    .borrador-button{

    }
    .action-buttons{
        .anular{
            background-color: #FF5E5E;
            margin-right:16px;
        }
        .presentar{
            background-color: #05AA05
        }
    }
}