.ui-search-input{
    display: flex;
    margin-bottom: 8px;
    .MuiOutlinedInput-root{
        border-radius: 4px;
        input{
            padding: 8px 14px;
            height: 28px;
        }
        fieldset{
            top: 0;
            legend{
                display: none;
                height: 0;
            }
        }
    }
}