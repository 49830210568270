@import "../../../colors/index.scss";

.pages-home-cumplimiento{
    .grid-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        .home-cards{
            border: none;
        }
    }
}
