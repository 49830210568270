.ui-file-preview {
    width: 80px;
    margin-right: 10px;
    cursor: pointer;
    svg{
        width: 64px;
        height: 64px;
    }
    @media (min-width: 768px) {
        svg {
            display: block;
            margin-left:auto;
            margin-right: auto;
            margin-bottom: 5px;
        }
    }
    div{
        width: 80px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }

    @media (max-width: 767px) {
        width: 60px;
        svg{
            width: 48px;
            height: 48px;
        }
        div{
            width: 50px;
        }
    }
}