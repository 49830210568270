@import "../../../colors/index.scss";

.template-form{
  .ui-form{
    margin: auto;
    display: flex;
    height: 100%;
    width: 70%;
    justify-content: center;
    align-items: center;
    border: 1px solid $medium-gray;
    flex-direction: column;
    padding: 32px;
    .MuiTextField-root {
      margin-bottom: 16px;
      width: 100%;
      input {
        height: 18px;
        font-size: 14px;
      }
      .MuiInputLabel-root {
        font-size: 14px;
      }
      &.textarea{
        input{
          height: 54px;
        }
      }
    }
    .MuiFormControl-root {
      margin-bottom: 16px;
      font-size: 14px;
      width: 100%;
    }
    .MuiButton-root{
      cursor: pointer;
      margin-top: 16px;
    }
    .MuiOutlinedInput-root{
      .MuiSelect-select{
        display: flex;
      }
      .MuiListItemIcon-root{
        min-width: 32px;
      }
    }
  }
}