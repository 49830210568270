@import "../../../colors/index.scss";

.contact-form{
  .ui-form{
    margin: auto;
    display: flex;
    height: 100%;
    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $medium-gray;
    padding: 32px;
    .MuiTextField-root {
      margin-bottom: 20px;
      input {
        height: 18px;
        font-size: 14px;
      }
      .MuiInputLabel-root {
        font-size: 14px;
      }
    }
    .MuiButton-root{
      margin-top: 20px;
      cursor: pointer;
    }
  }
}