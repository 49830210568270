.pages-submissions{
  .icon-wrapper{
    margin-bottom: 10px;
    div{
      cursor: pointer;
      svg{
        width: 36px;
        height: 36px;
      }
    }
  }
}