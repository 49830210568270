.pages-templates{
  .cursor-pointer {
    cursor: pointer;
  }
  .icon-wrapper{
    margin-bottom: 10px;
    div{
      cursor: pointer;
      svg{
        width: 36px;
        height: 36px;
      }
    }
  }
  .ui-table{
    .icons-wrapper {
      width: 100%;
      div{
        text-align: center;
        cursor: pointer;
      }
    }
  }
}