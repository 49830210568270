@import "../../../colors/index.scss";

.emails-table{
  .MuiChip-root {
    font-weight: bold;
    &.created {
      border-color: $yellow-status;
      color: $yellow-status;
    }
    &.sent{
      border-color: $green-status;
      color: $green-status;
    }
    &.error{
      border-color: $red-status;
      color: $red-status;
    }
  }
}
