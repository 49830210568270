@import "../../../colors/index.scss";

.requirement-detail{
  .ui-form{
    margin: auto;
    display: flex;
    height: 100%;
    width: 70%;
    justify-content: center;
    align-items: center;
    border: 1px solid $medium-gray;
    flex-direction: column;
    padding: 32px 10% 32px 10%;
    .content-wrapper{
      width: 100%;
    }
    .ui-form-label {
      &.row{
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
      }
    }
    .form-label {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      h6{
        margin-bottom: 5px;
      }
      img{
        width: 217px;
        border: 1px solid $medium-gray;
      }
    }
    .MuiTextField-root {
      margin-bottom: 16px;
      width: 100%;
      input {
        height: 18px;
        font-size: 14px;
      }
      .MuiInputLabel-root {
        font-size: 14px;
      }
      &.textarea{
        input{
          height: 54px;
        }
      }
    }
  }
}