@import "../../colors/index.scss";

.ui-sidebar{
  width: 200px;
  .MuiPaper-root{
    background-color: black;
  }
  &.MuiDrawer-docked{
    align-content: space-between;
    justify-content: space-between;
    height: 100%;
    .ui-menu{
      margin-right: 8px;
      margin-left: 8px;
    }
    .MuiDrawer-paper{
      width: 200px;
    }
    .selected{
      background-color: $primary;
      border-radius: 8px;
    }
  
    .menu-text{
      span{
        color: white;
        padding-left: 10px;
      }
    }
    .menu-icon{
      color:white;
      display: flex;
    }
    .list-item{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    
    .divider{
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      .MuiDivider-root{
        padding-top: 0;
        padding-bottom: 0;
        border-bottom-width: thin;
        border-color: #EAEEF3;
      }
    }
  }
}