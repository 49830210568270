body {
  margin: 0;
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Archivo', 'Segoe UI', 'Archivo', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;

  #root {
    margin: 0;
    height: 100%;
  }
  .app {
    margin: 0;
    height: 100%;
  }
}

html{
  height: 100%;
  font-family: Archivo, Roboto, Helvetica Neue, Arial, sans-serif;
}

code {
  font-family: 'Archivo', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}