$dark-blue : rgb(0,23,55,0.7);
$gray-border: rgba(72, 94, 144, 0.16);
$medium-gray: rgba(72, 94, 144, 0.30);
$green: #00AE46;
$red: #F6465D;
$medium-blue: #C4C4C4;
$gray-text: #5E5A5A;
$white: #ffff;
$primary: #327bf5;
$secondary: #63e5c5;
$celeri-dark-blue: #14366f;
$red-status: #EE0000;
$orange-status: #FF6600;
$yellow-status: #F9D71C;
$gray-status: #616161;
$green-status: green;
