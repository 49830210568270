@import "../../colors/index.scss";

.login-root {
    background-color: $primary;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login-box {
        width: 80%;
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
            
        
        .logo{
            width: 100%;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 10px;
            display: flex;
            img{
              width: 170px;
            }
          }
    
        .text-field {
            flex: auto;
            display: flex;
            width: 100%;
            padding: 8px;
        }
        
        .login-button {
            margin: 16px;
        }
    }

}
