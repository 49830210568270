@import "../../../colors/index.scss";

.requirement-form{
  .ui-form{
    margin: auto;
    display: flex;
    height: 100%;
    width: 70%;
    justify-content: center;
    align-items: center;
    border: 1px solid $medium-gray;
    flex-direction: column;
    padding: 10%;
    padding-top: 32px;
    .view-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      width: 100%;
      .view-button{
        height: fit-content;
        align-self: center;
        padding: 0;
        cursor: pointer;
        z-index: 5;
        margin-left: 10px;
      }

      .description {
        margin-top: 4px;
        margin-left: 16px;
        code {
          font-weight: bold;
          font-size: 10px;
          display: inline;
          padding: 4px 6px;
          margin-left: 2px;
          border-radius: 4px;
          background-color: #eff0f2;
        }
      }
    }
    .MuiTextField-root {
      width: calc(100% - 30px);
      input {
        height: 18px;
        font-size: 14px;
      }
      .MuiInputLabel-root {
        font-size: 14px;
        width: 100%;
      }
      &.textarea{
        input{
          height: 54px;
        }
      }
    }
    .MuiAutocomplete-root {
      width: calc(100% - 30px);
      .MuiTextField-root {
        width: 100%;
      }
      input {
        height: 18px;
        font-size: 14px;
      }
      .MuiInputLabel-root {
        font-size: 14px;
        width: 100%;
      }
      &.textarea{
        input{
          height: 54px;
        }
      }
    }
    .MuiFormControl-root {
      align-self: flex-start;
      font-size: 14px;
    }
    .ace_editor{
      width: calc(100% - 30px) !important;
      align-self: flex-start;
    }
    .MuiButton-root{
      cursor: pointer;
      margin-top: 16px;
    }
    .loading-dialog{
      display: flex;
      margin: 0 0 20px 10px;
      display: flex;
      align-items: center;
    }
  }
}