.pages-regulations{
  .icon-wrapper{
    margin-bottom: 10px;
    div{
      cursor: pointer;
      svg{
        width: 36px;
        height: 36px;
      }
    }
  }
  .options-regulations{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }
}
.menu-item{
  &.MuiMenuItem-root{
    min-height: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px;
    .MuiSvgIcon-root{
      margin-right: 12px;
    }
    .icon-text{
      height: 100%;
      padding: 10px;
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}
.menu-item-button{
  &.MuiMenuItem-root{
    min-height: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    .MuiSvgIcon-root{
      margin-right: 12px;
    }
  }
}


