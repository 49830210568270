@import "../../../colors/index.scss";

.days-countdown{
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    border-radius: 0.5rem;
    padding: 0.5em;
    &.green{
        background-color: $green-status;
        color: white;
    }
    &.red{
        background-color: $red-status;
        color: white;
    }
    &.yellow{
        background-color: $yellow-status;
        color: black;
    }
    &.orange{
        background-color: $orange-status;
        color: black;
    }
}