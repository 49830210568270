.legal-text{
    text-align: justify;
}
.table{
    width: 100%;
    margin: 10px 0 80px 0;
}
.table-head{
    background-color: #c6c6c6;
    border-bottom: 1px solid black;
    padding: 5px;
}
.table-th{
    border: 1px solid black;
    padding: 5px;
}
.table-td{
    border: 1px solid black;
    padding: 5px;
}